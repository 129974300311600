import { GlobalStyle, Main, Header, Footer, FooterLink, GameContainer, TileContainer, TileRow, Tile, KeyboardContainer, KeyboardRow, KeyboardKey, Flex, EndDialog, Heading, Row, ShareButton, ScoreContainer, ScoreText, InstructionContainer, InstructionText, Table, TableCell, TableHeader, TableRow } from "./styled";
import { BackspaceIcon } from "./icons";
import "./App.css";
import { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { evaluate } from 'mathjs';
import Cookies from 'js-cookie';
import { create, all } from 'mathjs';
import { bignumber } from "mathjs";

const config = {
  number: 'BigNumber', // Use 'BigNumber' type instead of 'number' for all calculations
  precision: 1024,       // Increase precision (default is 64, adjust as needed)
};
const math = create(all, config);


const allowedChars = [
  "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", 
  "+", "-", "*", "/", "^", "!", "<", ">",
  "(", ")", "|", "&", "="
];

const todaysNumsMap = {
  0: 1,
  1: 1,
  2: 1,
  3: 1,
  4: 1,
  5: 1,
  6: 1,
  7: 1,
  8: 1,
  9: 1,
  10: "!",
  11: "\^"
};

const todaysNumsArr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
const usedNumsArr = []

const equationLength = 20;


function App() {
  const [score, setScore] = useState(math.bignumber(0));

  const [todaysNums, setTodaysNums] = useState([ ...todaysNumsArr ]);
  const [usedNums, setUsedNums] = useState([ ...usedNumsArr ]);
  const [userEquation, setUserEquation] = useState(Array.from({ length: equationLength }).fill(""));
  const [realEquation, setRealEquation] = useState("");
  const [equationValue, setEquationValue] = useState(math.bignumber(0));
  const [markers, setMarkers] = useState(Array.from({ length: equationLength }).fill(""));
  const [isEndDialogVisible, setEndDialogVisible] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [highScore, setHighScore] = useState(math.bignumber(0));
  const [highScoreEquation, setHighScoreEquation] = useState("");
  const keys = Object.keys(todaysNums).map(Number).sort((a, b) => a - b);
  const [keyboardRows, setKeyboardRows] = useState([
    keys.map(key => todaysNums[key]),
    ["+", "-", "*", "/", "<<", ">>"],
    [ "share", "(", ")", "|", "&", "=", "backspace"],
  ]);


  const seed = new Date(); // Using current date as seed
  const random = (seed) => {
    const x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  };

  const seed_val = parseInt(String(seed.getUTCFullYear()) + String(seed.getUTCMonth() + 1) + String(seed.getUTCDate()))
  console.log("Number seed: " + seed_val)
  for (let i = 0; i < 10; i++) {
    todaysNumsMap[i] = Math.floor(random(seed_val * i) * 10); // Generating random values between 0 and 9
  }
  useEffect(() => {
    const storedData = Cookies.get('highestScore');
    if (storedData) {
      const { equation, highScore } = JSON.parse(storedData);
      setHighScoreEquation(equation);
      setHighScore(highScore);
    }
  }, []);
  
  useEffect(() => {
    if (score.gt(0)) {
      console.log(`Score updated: ${score}`);
      const storedData = Cookies.get('highestScore');
      if (storedData) {
        const { highScore } = JSON.parse(storedData);
        console.log("highScore: " + highScore + ", type: " + typeof(highScore))
        const highScoreBN = math.bignumber(highScore);
        if (score.gt(highScoreBN)) {
          const now = new Date();
          const midnightUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
          Cookies.set('highestScore', JSON.stringify({ equation: realEquation, highScore: score.toString() }), { expires: midnightUTC });
          setHighScoreEquation(realEquation);
          setHighScore(score.toString());
        }
      } else {
        const now = new Date();
        const midnightUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
        Cookies.set('highestScore', JSON.stringify({ equation: realEquation, highScore: score.toString() }), { expires: midnightUTC });
        setHighScoreEquation(realEquation);
        setHighScore(score.toString());
      }
    }
  }, [score, realEquation, equationValue]);
  

  useEffect(() => {
    if (realEquation != "") {
      console.log("Correct equation - score: " + getScore())
      setScore(getScore());
      updateMarkers("green");
    }
    else {
      console.log("Incorrect equation - score: " + 0)
      setScore(math.bignumber(0));
      updateMarkers("gray");
    }
  }, [realEquation, equationValue]);

  useEffect(() => {
    setKeyboardRows([
      todaysNums,
      ["+", "-", "*", "/", "<<", ">>"],
      [ "share", "(", ")", "|", "&", "=", "backspace"],
    ])
    console.log("New todays nums: " + todaysNums)

    document.addEventListener("keydown", handleKeyPress);

    const validWord = userEquation.map(index => isNaN(index) ? index : todaysNumsMap[index]).join("");

    checkSyntax(validWord)
    return () => document.removeEventListener("keydown", handleKeyPress);
  },[todaysNums, usedNums, userEquation])

  
  useEffect(() => {
    console.log("new used nums: " + usedNums)

  },[usedNums])

  const allKeys = keyboardRows.flat();

  let charIndex = useRef(0);

  const endGame = () => {
    setEndDialogVisible(true);
  };

  const invalid = () => {
    console.log("Incorrect equation - score: " + 0)
    setScore(math.bignumber(0));
    setRealEquation("");
    updateMarkers("gray");
  }

  const checkSyntax = (equation) => {
    // Check if all of the characters in the equation are allowed.
    for (const character of equation) {
        if (allowedChars.indexOf(character) === -1) {
            console.log("Invalid chars: " + character);
            invalid();
            return false;
        }
    }
    if (!equation.includes("=")) {
        console.log("no =");
        invalid();
        return false;
    }

    var split_eq = equation.split("=");
    console.log("seq: " + split_eq);

    try {
        // Evaluate each side of the equation using math.js with BigNumber configuration
        var eqresult = math.evaluate(split_eq[0]);
        for (const item of split_eq) {
            console.log("side: " + item);
            var result = math.evaluate(item);
            console.log("sideres: " + result);
            console.log("sidetypes: " + typeof(result) + ", " + typeof(eqresult))
            // Use math.js comparison for BigNumbers
            // I think math.equal may not have high enough precision for numbers this large,
            // so I'll just check against the string representation too
            if (!math.equal(eqresult, result) || String(eqresult) !== String(result)) {
                console.log("neq: ");
                setEquationValue(0);
                invalid();
                return false;
            }
        }
        // Assumption: setEquationValue can handle BigNumber or convert it to a desired format
        setEquationValue(eqresult); // Convert BigNumber result to JavaScript Number if necessary
    } catch (e) {
        console.log("Eval exception in checkSyntax()");
        setEquationValue(0);
        invalid();
        return false;
    }

    setRealEquation(equation);
    return true;
};


  const getScore = () => {
    let points = math.bignumber(0);
    let myUserEquation = [ ... userEquation ];
    for (let i = 0; i < userEquation.length; ++i) {
      if (userEquation[i] == "10") {
        myUserEquation[i] = "!";
      }
      else if (userEquation[i] == "11") {
        myUserEquation[i] = "\^";
      }
      else {
        myUserEquation[i] = userEquation[i];
      }
    }
    const acceptedGuess = myUserEquation.join("");
    console.log("aG: " + acceptedGuess)
    for (const char of acceptedGuess) {
      console.log("char: " + char)
      if (char === "+" || char === "-" || char === "=") {
        console.log("plusminus")
        points = points.add(10);
      } else if (char === "*" || char === "/") {
        console.log("multdiv")
        points = points.add(20);
      } else if (char === ">" || char === "<") {
        //Shifts are actually 50 points, but since we are doing this character by character, 
        //I'll just do it like this since I'm lazy.
        console.log("shift")
        points = points.add(25);
      } else if (char === "\^") {
        console.log("exp")
        points = points.add(30);
      } else if (char === "!") {
        console.log("fac")
        points = points.add(40);
      } else if (char !== "(" && char !== ")" && char !== "=" && isNaN(char)) {
        console.log("elseops")
        points = points.add(50);
      } else if (!isNaN(char)) {
        points = points.add(10);
      }
    }

    console.log("equationValue: " + equationValue)
    console.log("charPoints: " + points)
    points = points.add(points.mul(equationValue));
    return points;
  }

  const updateMarkers = (color) => {
    const updatedMarkers = [
      ...markers,
    ];

    for (let i = 0; i < equationLength; ++i) {
      updatedMarkers[i] = color;
    }

    setMarkers(updatedMarkers);
  }

  const submitEquation = () => {
    updateMarkers("green");
    endGame();
  };

  const backspace = () => {
    const charIndexLocal = charIndex.current;

    if (charIndexLocal !== 0) {
      const keys = Object.keys(todaysNums).map(Number).sort((a, b) => a - b);
      var erasedNum = Object.keys(usedNums).find(key => usedNums[key] === userEquation[charIndexLocal - 1]);
      console.log("erasedNum: " + userEquation[charIndexLocal - 1])
      console.log("erasedNumIdx: " + erasedNum)
      console.log("un before: " + keys.map(key => usedNums[key]))
      handleRemoveNumFromUsedNums(erasedNum)
      handleAddNumToTodaysNums(userEquation[charIndexLocal - 1])
      console.log("un after: " + keys.map(key => usedNums[key]))
      
      setUserEquation((prev) => {
        const newEq = [ ...prev ];
        newEq[charIndexLocal - 1] = "";
        return newEq;
      });


      charIndex.current = charIndexLocal - 1;
    }
  };

  const handleAddNumToTodaysNums = (num) => {
    if (isNaN(num)) {
      return;
    }
    setTodaysNums((prevTodaysNums) => [
        ...prevTodaysNums,
        num,
    ]);
  };
  
  const handleRemoveNumFromTodaysNums = (pos) => {
    setTodaysNums(todaysNums.filter((item, index) => index !== pos))
  };

  const handleAddNumToUsedNums = (num) => {
    setUsedNums( [...usedNums, num] )
  };
  
  const handleRemoveNumFromUsedNums = (pos) => {
    setUsedNums(usedNums.filter((item, index) => index != pos))
  };

  const enterKey = (pressedKey) => {
    console.log("Typek: " + typeof(pressedKey))
    const charIndexLocal = charIndex.current;

    if (!isNaN(pressedKey)) {
      pressedKey = parseInt(pressedKey)
    }

    console.log("Typek2: " + typeof(pressedKey))
    console.log("Typek2k: " + pressedKey)

    //! is always index 10 in the array, so we can hardcode this.
    if (pressedKey == "!") {
      pressedKey = 10;
    }
    //^ is always 11
    else if (pressedKey == "^") {
      pressedKey = 11;
    }

    if (todaysNums.includes(pressedKey) && !isNaN(pressedKey)) {
      var todaysNumsIndex = todaysNums.indexOf(parseInt(pressedKey))
      console.log("inPub: " + todaysNumsIndex)



      handleAddNumToUsedNums(parseInt(pressedKey))
      handleRemoveNumFromTodaysNums(todaysNumsIndex)
    }
    else if (!isNaN(pressedKey)) {
      return;
    }

    if (charIndexLocal < equationLength) {
      setUserEquation((prev) => {
        const newEq = [ ...prev ];
        newEq[charIndexLocal] = isNaN(pressedKey) ? pressedKey.toLowerCase() : pressedKey
        return newEq;
      });

      charIndex.current = charIndexLocal + 1;
    }
  };


  const keyPress = async (pressedKey) => {
    const validWord = userEquation.map(index => isNaN(index) ? index : todaysNumsMap[index]).join("");

    if (pressedKey === "share") {
      console.log("Word: " + validWord)
      if (checkSyntax(validWord)) {
        setRealEquation(validWord);
        console.log("Correct equation")
        submitEquation();
      }
      else {
        console.log("Incorrect equation")
      }
    } else if (pressedKey === "backspace") {
      backspace();
    } else if (pressedKey !== "share") {
      enterKey(pressedKey);
    }
  };


  const handleKeyPress = (e) => {
    console.log("charIndex: " + charIndex.current)
    console.log("key: " + e.key)
    if (charIndex.current >= equationLength && (e.key !== "Backspace" && e.key !== "Enter")) {
      console.log("Tiles full - ignoring")
      return;
    }
    var pressedKey = isNaN(e.key) ? e.key.toLowerCase() : parseInt(e.key);
    console.log("key: " + pressedKey)
    if (pressedKey === "enter") {
      pressedKey = "share";
    }

    console.log("tn:" + todaysNums)
    console.log("tn includes: " + todaysNums.includes(parseInt(pressedKey)))
    var matchingKeys = Object.keys(todaysNumsMap).filter(key => todaysNumsMap[key] == pressedKey)
    console.log("tnn: " + matchingKeys)
    var realNum = -1;
    var included = false;
    for (let mk of matchingKeys) {
      console.log("tod num: " + matchingKeys + ", mk: " + mk)
      if (todaysNums.includes(parseInt(mk))) {
        included = true;
        realNum = parseInt(mk)
        break;
      }
    }
    console.log("included: " + included)
    if (allKeys.includes(pressedKey) || included) {
      var pressedKeyReal = isNaN(pressedKey) ? pressedKey : realNum;
      console.log("enteringGuess: " + pressedKeyReal)
      console.log("eg type: " + typeof(pressedKeyReal))
      keyPress(pressedKeyReal);
    }
  };

  const handleOnScreenKeyClick = (key) => {
    console.log("charIndex: " + charIndex.current)
    console.log("key: " + key)
    if (charIndex.current >= equationLength && key !== "backspace" && key !== "share") {
      console.log("Tiles full - ignoring")
      return;
    }
    const pressedKey = isNaN(key) ? key.toLowerCase() : parseInt(key);
    console.log("clickedKey: " + pressedKey)
    console.log("enteringGuess: " + pressedKey)
    console.log("clickedKey type: " + typeof(pressedKey))
    keyPress(pressedKey);
  };

  const copyMarkers = () => {
    let shareText = `🧮 Equle ${String(seed.getUTCMonth() + 1) + "/" + String(seed.getUTCDate()) + "/" + String(seed.getUTCFullYear())}`;
    
    shareText += ` (${Object.values(todaysNumsMap)}):\nMy score: ${score} 🤓\n🌍 https://equle.augustin.tech/`;

    navigator.clipboard.writeText(shareText);
    setShareDialogOpen(true);
  };

  useEffect(() => {
    Modal.setAppElement("#share");

    document.addEventListener("keydown", handleKeyPress);

    return () => document.removeEventListener("keydown", handleKeyPress);
  }, []);


  return (
    <>
      <GlobalStyle />
      <Main>
        <Header>EQULE: {String(seed.getUTCMonth() + 1) + "/" + String(seed.getUTCDate()) + "/" + String(seed.getUTCFullYear())}</Header>
        <ScoreContainer>
          <ScoreText>Today's Best Score: {highScore == null ? "0" : highScore.toString()}</ScoreText>
        </ScoreContainer>
        <ScoreContainer>
          <ScoreText>Today's Best Equation: <br/> <center>{highScoreEquation}</center></ScoreText>
        </ScoreContainer>
        <ScoreContainer>
          <ScoreText>Current Score: {score.toString()}</ScoreText>
        </ScoreContainer>
        <GameContainer>
          <TileContainer>
              <TileRow key={0}>
                {userEquation.map((letter, i) => (
                  <Tile key={i} color={markers[i]}>
                    {isNaN(letter) ? letter : todaysNumsMap[letter]}
                  </Tile>
                ))}
              </TileRow>
          </TileContainer>
        </GameContainer>
        <KeyboardContainer>
          {keyboardRows.map((keys, i) => (
            <KeyboardRow key={i}>
              {i === 1 && <Flex item={0.5} />}
              {keys.map((key) => (
                <KeyboardKey
                  key={key}
                  onClick={() => handleOnScreenKeyClick(key)}
                  flex={["share", "backspace"].includes(key) ? 1.5 : 1}
                >
                  {key === "backspace" ? <BackspaceIcon /> : isNaN(key) ? key : todaysNumsMap[key]}
                </KeyboardKey>
              ))}
              {i === 1 && <Flex item={0.5} />}
            </KeyboardRow>
          ))}
        </KeyboardContainer>
        <InstructionContainer>
          <InstructionText>How to play:</InstructionText>
        </InstructionContainer>
        <InstructionContainer>
          <InstructionText><center>Using today's numbers, create an equation.</center></InstructionText>
        </InstructionContainer>
        <InstructionContainer>
          <InstructionText><center>Each number and operation (except parentheses) is worth a set number of points. The longer your equation is, the higher its value is, and the fancier your operations are, the higher you'll score.</center></InstructionText>
        </InstructionContainer>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>Operation</TableHeader>
              <TableHeader>Name</TableHeader>
              <TableHeader>Points</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            <TableRow>
              <TableCell>n</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Numerical_digit" target="_blank" rel="noopener noreferrer">Digits</FooterLink></TableCell>
              <TableCell>10</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>=</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Equals_sign" target="_blank" rel="noopener noreferrer">Equality</FooterLink></TableCell>
              <TableCell>10</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>+</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Plus_and_minus_signs" target="_blank" rel="noopener noreferrer">Addition</FooterLink></TableCell>
              <TableCell>10</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>-</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Plus_and_minus_signs" target="_blank" rel="noopener noreferrer">Subtraction</FooterLink></TableCell>
              <TableCell>10</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>*</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Multiplication" target="_blank" rel="noopener noreferrer">Multiplication</FooterLink></TableCell>
              <TableCell>20</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>/</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Division_(mathematics)" target="_blank" rel="noopener noreferrer">Divison</FooterLink></TableCell>
              <TableCell>20</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>^</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Exponentiation" target="_blank" rel="noopener noreferrer">Exponentiation</FooterLink></TableCell>
              <TableCell>30</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>!</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Factorial" target="_blank" rel="noopener noreferrer">Factorial</FooterLink></TableCell>
              <TableCell>40</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&lt;&lt;</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Bitwise_operation" target="_blank" rel="noopener noreferrer">Bitwise Left Shift</FooterLink></TableCell>
              <TableCell>50</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&gt;&gt;</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Bitwise_operation" target="_blank" rel="noopener noreferrer">Bitwise Right Shift</FooterLink></TableCell>
              <TableCell>50</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>|</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Bitwise_operation" target="_blank" rel="noopener noreferrer">Bitwise or</FooterLink></TableCell>
              <TableCell>50</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&</TableCell>
              <TableCell><FooterLink href="https://en.wikipedia.org/wiki/Bitwise_operation" target="_blank" rel="noopener noreferrer">Bitwise and</FooterLink></TableCell>
              <TableCell>50</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Bonus</TableCell>
              <TableCell>(pre-bonus score) * (value of equation)</TableCell>
              <TableCell>??</TableCell>
            </TableRow>
          </tbody>
        </Table>
        <br/>
        <div><center>
          <p>Enjoy Equle? Any support is greatly appreciated!</p><br/>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
              <input type="hidden" name="cmd" value="_donations" />
              <input type="hidden" name="business" value="YWZGQVER4Q9QE" />
              <input type="hidden" name="currency_code" value="USD" />
              <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
          </form></center>
        </div>
      <Footer>
        Copyright {String(seed.getUTCFullYear())} &nbsp; <FooterLink href="https://mitchellaugustin.com" target="_blank" rel="noopener noreferrer">Mitchell Augustin</FooterLink>
      </Footer>
      </Main>
      <div id="share">
        <Modal
          isOpen={isEndDialogVisible}
          onRequestClose={() => setEndDialogVisible(false)}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
          contentLabel="Share"
        >
          <EndDialog>
            <Heading>
              <center>Today's Best</center>
              <ScoreContainer>
                <ScoreText>Score: {highScore == null ? "0" : highScore.toString()}</ScoreText>
              </ScoreContainer>
              <ScoreContainer>
                <ScoreText>Equation: {highScoreEquation}</ScoreText>
              </ScoreContainer>
            </Heading>
            <Row>
              <h3>Share with your friends!&nbsp;</h3>
              <ShareButton onClick={copyMarkers} disabled={shareDialogOpen}>
                {shareDialogOpen ? "Copied!" : "Copy"}
              </ShareButton>
            </Row>
          </EndDialog>
        </Modal>
      </div>
    </>
  );
}

export default App;
