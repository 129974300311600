import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #252526;
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
`;
export const Main = styled.main`
  font-family: "Noto Sans", "Helvetica Neue", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  color: #a3a3a3;
  background-color: #252526;
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  font-weight: 700;
  font-size: 3.6rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #3a3a3c;
`;

export const GameContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
`;

export const TileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
`;

export const TileRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const Tile = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #3a3a3c;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 3.2rem;
  text-transform: uppercase;
  user-select: none;
  width: 80px;
  height: 80px;
  margin: 5px;

  ${({ color }) => {
    if (color === "green") {
      return `background-color: #75f9a8;`;
    }
    else {
      return `background-color: #a3a3a3;`;
    }
  }}

  color: #252526;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
`;

export const KeyboardContainer = styled.section`
  max-width: 950px;
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const KeyboardRow = styled.div`
  width: 100%;
  margin: 0 auto 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const KeyboardKey = styled.button`
  display: flex;
  padding: 0;
  margin: 0 4px 0 0;
  font-size: 2.0rem;
  justify-content: center;
  align-items: center;
  height: 65px;
  ${({ item }) => (item ? `flex: ${item};` : `flex: 1;`)}
  border: 0;
  border-radius: 4px;
  background-color: #686868;
  font-weight: bold;
  text-transform: uppercase;
  color: #d7dadc;
  cursor: pointer;
  user-select: none;
  &:last-of-type {
    margin: 0;
  }
`;

export const Flex = styled.div`
  ${({ item }) => `flex: ${item};`}
`;

export const EndDialog = styled.div`
  font-family: "Noto Sans", "Helvetica Neue", Arial, sans-serif;
  word-wrap: break-word;
  max-width: 100%;
`;

export const ShareButton = styled.button`
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 4px;
  border: 2px solid #3a3a3c;
  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: #818384;
  }
`;

export const Heading = styled.h2`
  border-bottom: 1px solid #3a3a3c;
  padding-bottom: 8px;
  font-weight: 700;
  font-size: 3.6rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto;
`;

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding-bottom: 10px;
  border-bottom: 3px solid #3a3a3c;
  word-wrap: break-word;
  max-width: 100%;
`;

export const ScoreText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #a3a3a3;
  padding-bottom: 10px;
  word-wrap: break-word;
  max-width: 100%;
`;

export const InstructionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

export const InstructionText = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #a3a3a3;
  margin-bottom: 15px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  color: #a3a3a3;
  text-align: center;
`;

export const FooterLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Table = styled.table`
  width: 30%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background-color: #686868;
`;

export const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

export const TableRow = styled.tr`
  &:hover {
    background-color: #f5f5f5;
  }
`;